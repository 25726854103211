import React from 'react';
import './privacy-page.css'; // Add CSS file for additional styling if needed
import Header from './Header';
import { useConsolidatedUser } from '../contexts/ConsolidatedUserContext';
import LockLottie from '../ui/LockLottie';

const PrivacyPage = () => {

  const { currentUser, userData, logout } = useConsolidatedUser();

  return (
    <>
      <Header />


      <div className="privacy-page">
      <div className="privacy-container">
        <div className="lock" align="center"> <LockLottie /></div>
      
        <h1 className="privacy-title">Your Privacy and Security Matter</h1>
        
        <h2 className="security-subtitle">Safeguarding Your Most Personal Thoughts</h2>
        <p className="security-text">
          At MCA, we understand that you're sharing some of your most personal thoughts and feelings. We're honored by your trust, and we take the responsibility of protecting your information extremely seriously.
        </p>
        
        <h2 className="security-subtitle">Robust Protection Measures</h2>
        <p className="security-text">
          We've implemented strong, industry-standard security measures to protect your data. Your information is guarded by advanced encryption, both when it's traveling to our servers and when it's stored. 
        </p>
        <p className="security-text">
          In simple terms, it's like your data is in a secure digital vault. Even in the highly unlikely event of unauthorized access, your information would remain unreadable and protected.
        </p>
        
        <h2 className="security-subtitle">Ongoing Security Enhancements</h2>
        <p className="security-text">
          We recognize that the digital landscape is constantly evolving. That's why we're committed to actively enhancing our security measures on an ongoing basis. We regularly review and update our systems to address potential vulnerabilities and incorporate the latest security best practices.
        </p>
        
        <h2 className="security-subtitle">Strict Data Access Policies</h2>
        <p className="security-text">
          Beyond encryption, we have implemented rigorous policies governing data access:
        </p>
        <ul>
          <li><p className="security-text">Access to user data is strictly limited to essential personnel only.</p></li>
          <li><p className="security-text">All data access is logged and monitored to prevent and detect any unauthorized use.</p></li>
          <li><p className="security-text">We employ the principle of least privilege, ensuring staff members only have access to the specific data necessary for their role.</p></li>
          <li><p className="security-text">Regular audits are conducted to ensure compliance with our data access policies.</p></li>
        </ul>
        <p className="security-text">
          These policies are designed to add an extra layer of protection to your personal information, ensuring it's handled with the utmost care and respect.
        </p>
        
        <h2 className="security-subtitle">Your Data, Your Control</h2>
        <p className="security-text">
          We believe your data belongs to you. We only keep detailed responses long enough to generate your personalized reports. After that, we retain only the general scores needed to support your ongoing journey. You can request to view, change, or delete your data at any time.
        </p>
        
        <h2 className="security-subtitle">Absolutely No Sharing or Selling</h2>
        <p className="security-text">
          Your personal information is not a product. We do not sell, share, or trade your data with any third parties. Period. Your information is used solely to provide you with our marriage strengthening services.
        </p>
        
        <h2 className="security-subtitle">Our Promise to You</h2>
        <p className="security-text">
          Your trust is the foundation of our relationship. We promise to always handle your information with the utmost care and respect. This includes not only our technical measures but also our commitment to ethical data practices and transparency.
        </p>
        
        <h2 className="security-subtitle">We're Here for You</h2>
        <p className="security-text">
          If you ever have questions or concerns about your data's security or our privacy practices, we're just a message away. Your peace of mind is crucial to us, and we're always happy to discuss our security measures and data policies in more detail.
        </p>
        
        <p className="security-text">
          Thank you for trusting the Marriage Connection Assessment. Together, we're creating a secure path to a stronger, more fulfilling relationship.
        </p>

        <p className="outro-text">
          Jason and Lauren Vallotton
        </p>
      </div>
    </div>
    </>
  );
};

export default PrivacyPage;