import styled, { css } from 'styled-components';

// Helper function to get size-specific styles
const getSizeStyles = (size) => {
  switch (size) {
    case 'small':
      return css`
        font-size: 14px;
        padding: 8px 16px;
      `;
    case 'large':
      return css`
        font-size: 22px;
        padding: 16px 24px;
      `;
    default: // medium
      return css`
        font-size: 18px;
        padding: 12px 20px;
      `;
  }
};

// Styled component for PillButton
export const PillButtonNoOutline = styled.button`
  font-family: 'Roboto Slab', serif;
  font-weight: 900;
  width: ${(props) => props.width || 'fit-content'};
  max-width: 100%;
  min-width: 110px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  color: ${(props) =>
    props.theme === 'light' ? 'var(--color_1)' : 'var(--color_3)'};
  
  /* Add border-radius for pill shape */
  border-radius: 50px;
  
  /* Remove the border */
  border: none;

  ${(props) => getSizeStyles(props.size)}

  /* Update the transition property */
  transition: background-color 0.07s ease-in-out, color 0.07s ease-in-out;

  &:hover {
    background-color: var(--color_4);
    color: #ffffff;
  }

  .triangle {
    display: inline-block;
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 10px solid currentColor;
    margin-left: 7px;
    position: relative;
    top: 1px;

    /* Ensure the triangle transitions its border color */
    transition: border-left-color 0.15s ease-in-out;
  }

  &.narrow {
    width: 25%;
  }
  &.medium {
    width: 50%;
  }
  &.wide {
    width: 75%;
  }
`;

export default PillButtonNoOutline;