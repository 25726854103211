import React from 'react';
import Lottie from 'react-lottie-player';
import loaderAnimation from './loader.json'; // Make sure this path is correct
import './loader.css';

const Loader = () => {
  console.log('Loader component rendering');

  return (
    <div className="loader-container" style={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(255, 255, 255, 0.8)',
      zIndex: 9999,
    }}>
      <Lottie
        loop
        animationData={loaderAnimation}
        play
        style={{ width: 150, height: 150 }}
        rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
      />
      <div style={{
        position: 'absolute',
        bottom: '20px',
        fontSize: '18px',
        color: '#333',
      }}>
      </div>
    </div>
  );
};

export default Loader;