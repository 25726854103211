import React, { useState, useRef, useEffect, useCallback } from 'react';
import './assessment-block.css';

const AssessmentBlock = ({ userAssessment, spouseAssessment, userData, spouseName }) => {
    const [orangeHeight, setOrangeHeight] = useState(0);
    const [spouseOrangeHeight, setSpouseOrangeHeight] = useState(0);
    const [isVisible, setIsVisible] = useState(false);
    const [aspectAnimations, setAspectAnimations] = useState({});
    const blockRef = useRef(null);
    const aspectRefs = useRef({});
    const hasSpouseData = !!spouseAssessment;

    const checkVisibility = useCallback(() => {
        const isElementVisible = (element) => {
            if (!element) return false;
            const rect = element.getBoundingClientRect();
            return rect.top < window.innerHeight && rect.bottom > 0;
        };
    
        const blockVisible = isElementVisible(blockRef.current);
    
        // Only set heights when the block becomes visible
        if (blockVisible && !isVisible) {
            setIsVisible(true);
            setOrangeHeight((userAssessment.assessmentScore / userAssessment.maxScore) * 100);
            setSpouseOrangeHeight(spouseAssessment ? (spouseAssessment.assessmentScore / spouseAssessment.maxScore) * 100 : 0);
        } else if (!blockVisible && isVisible) {
            setIsVisible(false);
            setOrangeHeight(0);
            setSpouseOrangeHeight(0);
        }
    
        Object.keys(aspectRefs.current).forEach(key => {
            const element = aspectRefs.current[key];
            const aspectVisible = isElementVisible(element);
            const [userOrSpouse, , index] = key.split('-');
            const aspect = userOrSpouse === 'user'
                ? userAssessment.processedAspects[parseInt(index)]
                : spouseAssessment?.processedAspects[parseInt(index)];
    
            setAspectAnimations(prev => ({
                ...prev,
                [key]: aspectVisible ? (aspect.totalScore / aspect.aspectMaxScore) * 100 : 0
            }));
        });
    }, [userAssessment, spouseAssessment, isVisible]);

    useEffect(() => {
        const handleScroll = () => checkVisibility();
        window.addEventListener('scroll', handleScroll);
        checkVisibility(); // Initial check
        return () => window.removeEventListener('scroll', handleScroll);
    }, [checkVisibility]);

    const renderAspectScore = (aspect, index, isUser) => {
        const name = isUser ? (userData?.firstName || 'User') : (spouseName || 'Spouse');
        const key = `${isUser ? 'user' : 'spouse'}-aspect-${index}`;

        return (
            <div key={key} className="aspect-score">
                <div className="aspect-header">
                    <span className="aspect-name">{name}</span>
                    <span className="aspect-score-description">{aspect.scoreDescription}</span>
                </div>
                <div
                    ref={el => aspectRefs.current[key] = el}
                    className="aspect-progress-bar"
                >
                    <div
                        className="aspect-progress"
                        style={{ width: `${aspectAnimations[key] || 0}%` }}
                    ></div>
                </div>
                <div className="aspect-details">
                    <span>Score: {aspect.totalScore}/{aspect.aspectMaxScore}</span>
                    <span className="aspect-confidence-value">CN: {aspect.confidenceValue.toFixed(2)}</span>
                </div>
            </div>
        );
    };

    return (
        <div className="dark-results-block">
            <div className="assessment-block" ref={blockRef}>
                <div className="clear-results-block">
                    <div className="orange-title">{userAssessment.title}</div>
                </div>
                <p className="assessment-description">{userAssessment.description}</p>
                <div className={`columns ${!hasSpouseData ? 'single-column' : ''}`}>
                    <div className={`column ${!hasSpouseData ? 'full-width' : ''}`}>
                        <div className="darker-gray-block">
                            <div
                                className="orange-progress"
                                style={{ height: `${orangeHeight}%` }}
                            ></div>
                        </div>
                        <p className="name-label">{userData?.firstName || 'User'}</p>
                    </div>
                    {hasSpouseData && (
                        <div className="column">
                            <div className="darker-gray-block">
                                <div
                                    className="orange-progress"
                                    style={{
                                        height: `${spouseOrangeHeight}%`,
                                        opacity: spouseAssessment ? 1 : 0.5
                                    }}
                                ></div>
                            </div>
                            <p className="name-label">{spouseName || 'Spouse'}</p>
                        </div>
                    )}
                </div>

                <p className="name-label-totals">
                    <b>{userData?.firstName || 'User'}'s results: </b>
                    {`${userAssessment.assessmentScoreDescription}`}
                    <span className="score-highlight"> Score: {`${userAssessment.assessmentScore || 0} / ${userAssessment.maxScore || 'Max Score'}`}</span>
                </p>

                {hasSpouseData && (
                    <p className="name-label-totals">
                        <b>{spouseName || 'Spouse'}'s results: </b>
                        {`${spouseAssessment.assessmentScoreDescription}`}
                        <span className="score-highlight"> Score: {`${spouseAssessment.assessmentScore || 0} / ${spouseAssessment.maxScore || 'Max Score'}`}</span>
                    </p>
                )}

                <p className="assessement-score-description"></p>

                <h2 className="aspect-title">- Detailed Aspects -</h2>
                {userAssessment.processedAspects.map((aspect, index) => (
                    <div key={`aspect-group-${index}`} className="aspect-group">
                        {index !== 0 && <hr className="custom-faint-hr" />}
                        <h3 className="aspect-title">{aspect.title}</h3>
                        <p className="aspect-description">{aspect.description}</p>
                        {renderAspectScore(aspect, index, true)}
                        {spouseAssessment && spouseAssessment.processedAspects[index] &&
                            renderAspectScore(spouseAssessment.processedAspects[index], index, false)}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default AssessmentBlock;