import React from 'react';
import Header from './Header';
import { useConsolidatedUser } from '../contexts/ConsolidatedUserContext';

const ProfilePage = () => {
  const { currentUser, userData, logout } = useConsolidatedUser(); // Get the required data from context

  // Handle the logout action
  const handleLogout = async () => {
    try {
      await logout();
      // Optionally redirect or show a success message
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };



  return (
    <>
      <Header />
      Profile Page.
    </>
  );
};

export default ProfilePage;