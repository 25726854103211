import React from 'react';
import './progress-bar.css';

const ProgressBar = ({ currentIndex, totalQuestions }) => {
  const progress = (currentIndex / totalQuestions) * 100;

  return (
    <div className="progress-bar-container">
      <div 
        className="progress-bar-fill" 
        style={{ width: `${progress}%` }}
      ></div>
    </div>
  );
};

export default ProgressBar;