import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';
import { db } from '../auth/firebase';
import {
    getAuth,
    signOut,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    sendEmailVerification,
    sendPasswordResetEmail,
    applyActionCode,
    onAuthStateChanged
} from 'firebase/auth';
import { doc, getDoc, setDoc } from 'firebase/firestore';

const ConsolidatedUserContext = createContext();

export const useConsolidatedUser = () => useContext(ConsolidatedUserContext);

export const ConsolidatedUserProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const auth = getAuth();

    console.log('ConsolidatedUserProvider rendering');

    const startLoading = useCallback(() => {
        setLoading(true);
    }, []);

    const stopLoading = useCallback(() => {
        setLoading(false);
    }, []);

    // Firebase auth state listener
    useEffect(() => {
        console.log('Consolidated Context --> Auth State Check');
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            setCurrentUser(user);
            if (user) {
                try {
                    const userDocRef = doc(db, 'users', user.uid);
                    const userDocSnapshot = await getDoc(userDocRef);
                    if (userDocSnapshot.exists()) {
                        const data = userDocSnapshot.data();
                        setUserData({
                            ...data,
                            isAdmin: data.isAdmin || false // Ensure isAdmin is always defined
                        });
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                    setError('Error fetching user data.');
                }
            } else {
                setUserData(null);
            }
            setLoading(false); // Stop loading after checking auth state
        });

        return () => unsubscribe(); // Cleanup listener on unmount
    }, [auth]);

    const updateUserData = async (newData) => {
        if (currentUser) {
            startLoading();
            setError(null);
            try {
                const userRef = doc(db, 'users', currentUser.uid);
                await setDoc(userRef, newData, { merge: true });
                setUserData((prevData) => ({ ...prevData, ...newData }));
            } catch (error) {
                console.error('Error updating user data:', error);
                setError(error.message);
            } finally {
                stopLoading();
            }
        }
    };

    const logout = async () => {
        try {
            await signOut(auth);
            setCurrentUser(null);
            setUserData(null);
        } catch (error) {
            setError(error.message);
        }
    };

    const signUp = async (email, password) => {
        startLoading();
        setError(null);
        try {
            console.log('Attempting to create user account...');
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;
    
            console.log('User account created successfully. Sending email verification...');
            await sendEmailVerification(user);
    
            // Fetch user data from Firestore (assuming it's stored in the 'users' collection)
            const userDocRef = doc(db, 'users', user.uid);
            const userDocSnapshot = await getDoc(userDocRef);
    
            if (userDocSnapshot.exists()) {
                const userData = userDocSnapshot.data(); // Fetch the user's data
                setUserData({
                    ...userData,
                    isAdmin: userData.isAdmin || false // Ensure isAdmin is always defined
                });
            } else {
                console.log('No user data found in Firestore, creating a new document...');
                // Optionally, create a Firestore document for this new user
                const newUserData = { isAdmin: false }; // Set default isAdmin value
                await setDoc(userDocRef, newUserData);
                setUserData(newUserData);
            }
    
            console.log('Email verification sent. Signup process completed.');
            return userCredential;
        } catch (error) {
            console.error('Error during signup:', error);
            setError(getErrorMessage(error.code));
            throw error;
        } finally {
            stopLoading();
        }
    };

    const signIn = async (email, password) => {
        setLoading(true);
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            setCurrentUser(userCredential.user);
        
            // Fetch user data after sign-in
            const userDocRef = doc(db, 'users', userCredential.user.uid);
            const userDocSnapshot = await getDoc(userDocRef);
            if (userDocSnapshot.exists()) {
                const data = userDocSnapshot.data();
                setUserData({
                    ...data,
                    isAdmin: data.isAdmin || false // Ensure isAdmin is always defined
                });
            } else {
                setError('User data not found.');
            }
        
            return userCredential;
        } catch (error) {
            console.error('Error in signIn:', error);
            setError(error.message);
            throw error;
        } finally {
            setLoading(false);
        }
    };

    const verifyEmail = async (oobCode) => {
        startLoading();
        try {
            await applyActionCode(auth, oobCode);
        } catch (error) {
            setError(error.message);
        } finally {
            stopLoading();
        }
    };

    const sendPasswordReset = async (email) => {
        startLoading();
        try {
            // Check if the email is valid before sending the reset request
            if (!isValidEmail(email)) {
                throw new Error('Please enter a valid email address');
            }

            await sendPasswordResetEmail(auth, email);
        } catch (error) {
            // Set error message to be displayed in UI
            setError(error.message || 'Error sending password reset email.');
        } finally {
            stopLoading();
        }
    };

    // Simple email validation function
    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const getErrorMessage = (errorCode) => {
        switch (errorCode) {
            case 'auth/email-already-in-use':
                return 'This email is already in use. Please try logging in instead.';
            case 'auth/invalid-email':
                return 'Please enter a valid email address.';
            case 'auth/weak-password':
                return 'Your password is too weak. Please choose a stronger password.';
            case 'auth/operation-not-allowed':
                return 'Account creation is currently disabled. Please contact support.';
            default:
                return 'An unexpected error occurred. Please try again.';
        }
    };

    const value = {
        currentUser,
        userData,
        loading,
        error,
        setError,
        setUserData,
        updateUserData,
        logout,
        signUp,
        signIn,
        verifyEmail,
        sendPasswordReset,
        startLoading,
        stopLoading,
        isAdmin: userData?.isAdmin || false // Add this line to expose isAdmin directly
    };

    return (
        <ConsolidatedUserContext.Provider value={value}>
            {!loading && children}  {/* Render children only after loading */}
        </ConsolidatedUserContext.Provider>
    );
};