import React, { useState, useEffect, useRef } from 'react';
import './header.css';
import PillButton from '../ui/PillButton';
import { useNavigate } from 'react-router-dom';
import { useConsolidatedUser } from '../contexts/ConsolidatedUserContext';

const Header = () => {
  const navigate = useNavigate();
  const { currentUser, userData, logout } = useConsolidatedUser(); // Get currentUser, userData, and logout function from context
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const buttonRef = useRef(null);

  const [isAdmin, setIsAdmin] = useState(false); // State to check if the user is an admin

  // Function to handle menu button click
  const handleMenuButtonClick = () => {
    setIsMenuOpen(prevState => !prevState);
  };

  // Auto-close the menu after a few seconds
  useEffect(() => {
    let timer;
    if (isMenuOpen) {
      // Set a timer to close the menu after 5 seconds (5000ms)
      timer = setTimeout(() => {
        setIsMenuOpen(false);
      }, 5000);
    }

    // Clean up the timer if the menu is closed manually or the component is unmounted
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [isMenuOpen]);

  // Function to check if the user is an admin
  useEffect(() => {
    if (currentUser && userData) {
      setIsAdmin(userData.isAdmin || false);
    } else {
      setIsAdmin(false);
    }
  }, [currentUser, userData]);

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/landing-page');
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  // Handle menu item clicks
  const handleMenuItemClick = (item) => {
    if (item.action) {
      item.action();
    } else {
      navigate(item.path);
    }
    setIsMenuOpen(false); // Close the menu after clicking an item
  };

  // Define menu items based on authentication and admin status
  const menuItems = !currentUser
    ? [
        { label: 'Home', path: '/landing-page' },
        { label: 'Sign Up', path: '/request-access' },
        {
          label: 'Sign In',
          action: () => {
            if (currentUser) {
              navigate('/home-page'); // If logged in, send to dashboard
            } else {
              navigate('/login'); // If not logged in, send to login page
            }
          },
        },
        { label: 'Contact', path: '/contact-page' },
      ]
    : [
        { label: 'Home', path: '/landing-page' },
        { label: 'Dashboard', path: '/home-page' },
        //{ label: 'Profile', path: '/profile' },
        ...(userData?.mcaCongratulations
          ? [{ label: 'Results', path: '/results' }]
          : []),
        ...(isAdmin
          ? [
              { label: 'Edit', path: '/edit' },
              { label: 'Invite', path: '/invite' },
            ]
          : []),
        { label: 'Contact', path: '/contact-page' },
        { label: 'Log Out', action: handleLogout },
      ];

  return (
    <header className="header">
      <h1 className="header-title">THE MARRIAGE CONNECTION ASSESSMENT</h1>
      <PillButton
        size="small"
        theme="light"
        width="narrow"
        onClick={handleMenuButtonClick}
        ref={buttonRef}
      >
        {'Menu'}
        <span className="triangle"></span>
      </PillButton>

      {isMenuOpen && (
        <div className="dropdown-menu" ref={menuRef}>
          <ul className="menu-list">
            {menuItems.map((item, index) => (
              <li key={index} className="menu-item">
                <button 
                  className="menu-button" 
                  onClick={() => handleMenuItemClick(item)}
                >
                  {item.label}
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </header>
  );
};

export default Header;