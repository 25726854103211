import React from 'react';
import ErrorLottie from '../ui/ErrorLottie';
import './error-page.css';
import { useLocation } from 'react-router-dom';
import Header from './Header';
import { useConsolidatedUser } from '../contexts/ConsolidatedUserContext';

const ErrorPage = () => {
  const location = useLocation();
  const errorMessage = location.state?.errorMessage || "An unknown error occurred."; // Fallback message

  // Get currentUser, userData, and handleLogout from the context
  const { currentUser, userData, logout } = useConsolidatedUser();

  const handleLogout = async () => {
    try {
      await logout(); // Execute logout
    } catch (err) {
      console.error('Logout failed:', err);
    }
  };

  return (
    <div>
      <Header />
      <div className="error-page-container">
        {/* Lottie animation */}
        <ErrorLottie />

        {/* Text content */}
        <div className="error-page-box">
          <h2>Sorry!</h2>
          <p>There was an error processing your request. Please try again later.</p>
          <p className="error-message">{errorMessage}</p>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;