import React, { useState } from 'react';
import Lottie from 'react-lottie-player';
import CheckmarkLottie from '../ui/CheckmarkLottie';
import './congratulations-page.css';

const CongratulationsPage = ({ currentUser, onClose }) => {
  const [fadeOut, setFadeOut] = useState(false);

  const handleOverlayClick = async () => {
    setFadeOut(true);

    // Step 1: Update the local state
    setTimeout(() => {
      onClose(); // Close the overlay and remove it from the DOM
    }, 300);
  };

  return (

    <div className={`overlay ${fadeOut ? 'fade-out' : ''}`}
      onClick={handleOverlayClick}
      style={{ pointerEvents: fadeOut ? 'none' : 'auto' }}
    >
      <div className="congratulations-container">
        <CheckmarkLottie />
        <p className="congrats-title">Congratulations!</p>
        <h1>Assessment complete!</h1>
        <p>Tap anywhere to continue</p>
      </div>
    </div>
  );
};

export default CongratulationsPage;