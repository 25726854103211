import React, { useState, useEffect } from 'react';
import { doc, setDoc, deleteDoc, updateDoc } from 'firebase/firestore';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { db } from '../../auth/firebase';
import Loader from '../../ui/Loader';
import { useConsolidatedUser } from '../../contexts/ConsolidatedUserContext';

const EditQuestions = ({
  assessmentData,
  questionsData,
  setQuestionsData,
}) => {

  const { currentUser, userData, loading, logout } = useConsolidatedUser();
  const [questionToDelete, setQuestionToDelete] = useState(null);
  const [showQuestionDeletePopup, setShowQuestionDeletePopup] = useState(false);
  const [showAddQuestionPopup, setShowAddQuestionPopup] = useState(false);
  const [newQuestionText, setNewQuestionText] = useState('');
  const [newQuestionAnswers, setNewQuestionAnswers] = useState('');
  const [newQuestionIndex, setNewQuestionIndex] = useState(0);
  const [loadingData, setLoadingData] = useState(false);

  // New state variable for messages
  const [questionUpdateMessage, setQuestionUpdateMessage] = useState({ text: '', isError: false });

  // Clear message after 5 seconds
  useEffect(() => {
    if (questionUpdateMessage.text) {
      const timer = setTimeout(() => {
        setQuestionUpdateMessage({ text: '', isError: false });
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [questionUpdateMessage]);

  // Handle question input changes
  const handleQuestionChange = (index, field, value) => {
    const updatedQuestions = [...questionsData];
    updatedQuestions[index][field] = value;
    setQuestionsData(updatedQuestions);
  };

  // Save individual question
  // Save individual question
  const saveQuestion = async (question, index) => {
    setLoadingData(true);
    try {
      const questionDocRef = doc(db, 'questions', `${assessmentData.id}-${question.index}`);

      // If answersString exists, convert it to an array
      if (question.answersString) {
        question.answers = question.answersString.split(',').map((ans) => ans.trim());
        // Remove temporary answersString field
        delete question.answersString;
      }

      // If answersString doesn't exist, we assume answers is already an array
      if (!question.answers || !Array.isArray(question.answers)) {
        throw new Error('Invalid answers format. It should be a comma-separated string or an array.');
      }

      // Update the question document in Firestore
      await updateDoc(questionDocRef, question);

      // Update the assessment document with the question references
      const updatedQuestions = questionsData.map((q) => doc(db, 'questions', `${assessmentData.id}-${q.index}`));
      const assessmentDocRef = doc(db, 'assessments', assessmentData.id);
      await updateDoc(assessmentDocRef, {
        questions: updatedQuestions, // Store references, not strings
      });

      setQuestionUpdateMessage({ text: `Question ${index + 1} saved successfully!`, isError: false });
      setLoadingData(false);
    } catch (error) {
      console.error('Error saving question:', error);
      setQuestionUpdateMessage({ text: 'An error occurred while saving the question ...', isError: true });
      setLoadingData(false);
    }
  };

  const deleteQuestion = (index) => {
    setQuestionToDelete(index);  // Set the question to delete
    setShowQuestionDeletePopup(true); // Show confirmation popup
  };
  
  const handleDeleteQuestionConfirm = async () => {
    const index = questionToDelete;
    const question = questionsData[index];
    setLoadingData(true);
    try {
      // Delete question document
      const questionDocRef = doc(db, 'questions', `${assessmentData.id}-${question.index}`);
      await deleteDoc(questionDocRef);

      // Remove question from state
      const updatedQuestions = [...questionsData];
      updatedQuestions.splice(index, 1);

      // Reassign indexes and update questions
      for (let i = 0; i < updatedQuestions.length; i++) {
        updatedQuestions[i].index = i;
        const updatedQuestionDocRef = doc(db, 'questions', `${assessmentData.id}-${updatedQuestions[i].index}`);
        await setDoc(updatedQuestionDocRef, updatedQuestions[i]);
      }

      setQuestionsData(updatedQuestions);

      // Update assessment document with new questions array
      const questionRefs = updatedQuestions.map((q) => doc(db, 'questions', `${assessmentData.id}-${q.index}`));
      const assessmentDocRef = doc(db, 'assessments', assessmentData.id);
      await updateDoc(assessmentDocRef, {
        questions: questionRefs, // Store references, not strings
      });

      setLoadingData(false);
      setQuestionUpdateMessage({ text: 'Question deleted successfully!', isError: false });
    } catch (error) {
      console.error('Error deleting question:', error);
      setLoadingData(false);
      setQuestionUpdateMessage({ text: 'An error occurred while deleting the question.', isError: true });
    } finally {
      setLoadingData(false);
      setShowQuestionDeletePopup(false);
      setQuestionToDelete(null);
    }
  };

  // Handle drag and drop reordering
  const onDragEnd = async (result) => {
    if (!result.destination) return;
    setLoadingData(true);
    const updatedQuestions = Array.from(questionsData);
    const [movedQuestion] = updatedQuestions.splice(result.source.index, 1);
    updatedQuestions.splice(result.destination.index, 0, movedQuestion);

    // Reassign indexes and update questions in Firestore
    for (let i = 0; i < updatedQuestions.length; i++) {
      updatedQuestions[i].index = i;
      const questionDocRef = doc(db, 'questions', `${assessmentData.id}-${updatedQuestions[i].index}`);
      await setDoc(questionDocRef, updatedQuestions[i]);
    }

    setQuestionsData(updatedQuestions);

    // Update assessment document with new questions array
    const questionRefs = updatedQuestions.map((q) => doc(db, 'questions', `${assessmentData.id}-${q.index}`));
    const assessmentDocRef = doc(db, 'assessments', assessmentData.id);
    await updateDoc(assessmentDocRef, {
      questions: questionRefs, // Store references, not strings
    });

    setLoadingData(false);
    setQuestionUpdateMessage({ text: 'Questions reordered successfully!', isError: false });
  };

  // Function to add a new question
  const addNewQuestion = async () => {
    if (!assessmentData) return;

    // Validate inputs
    if (newQuestionText.trim() === '') {
      setQuestionUpdateMessage({ text: 'Question text cannot be empty.', isError: true });
      return;
    }

    if (newQuestionAnswers.trim() === '') {
      setQuestionUpdateMessage({ text: 'Answers cannot be empty.', isError: true });
      return;
    }

    let index = parseInt(newQuestionIndex);
    if (isNaN(index) || index < 0 || index > questionsData.length) {
      setQuestionUpdateMessage({
        text: `Index must be between 0 and ${questionsData.length}.`,
        isError: true,
      });
      return;
    }

    try {
      setLoadingData(true);

      // Increment indexes and update IDs of existing questions from the selected index onwards
      const updatedQuestions = questionsData.map((q) => {
        if (q.index >= index) {
          const newIndex = q.index + 1;
          const updatedId = `${assessmentData['url-title']}-${newIndex}`; // Update ID with new index
          return { ...q, index: newIndex, id: updatedId };
        }
        return q;
      });

      // Update existing questions in Firestore
      for (const question of updatedQuestions) {
        const questionDocRef = doc(db, 'questions', question.id);
        await setDoc(questionDocRef, question);
      }

      // Create new question object
      const newQuestionId = `${assessmentData['url-title']}-${index}`;
      const newQuestion = {
        assessment: doc(db, 'assessments', assessmentData.id),  // Store as Firestore ref
        index: index,
        text: newQuestionText.trim(),
        answers: newQuestionAnswers
          .split(',')
          .map((ans) => ans.trim())
          .filter((ans) => ans !== ''),
        isNegative: false, // Default new question to isNegative false
        id: newQuestionId, // Assign new ID based on url-title and index
      };

      // Save new question to Firestore
      const newQuestionDocRef = doc(db, 'questions', newQuestionId);
      await setDoc(newQuestionDocRef, newQuestion);

      // Update questionsData state
      const allQuestions = [...updatedQuestions, newQuestion];
      // Sort questions by index
      allQuestions.sort((a, b) => a.index - b.index);
      setQuestionsData(allQuestions);

      // Update assessment's questions array with Firestore refs
      const questionRefs = allQuestions.map((q) => doc(db, 'questions', q.id));
      const assessmentDocRef = doc(db, 'assessments', assessmentData.id);
      await updateDoc(assessmentDocRef, {
        questions: questionRefs, // Store references, not strings
      });

      setQuestionUpdateMessage({ text: 'New question added successfully!', isError: false });
      // Close popup and reset inputs
      setShowAddQuestionPopup(false);
      setNewQuestionText('');
      setNewQuestionAnswers('');
      setNewQuestionIndex(0);
      setLoadingData(false);
    } catch (error) {
      setLoadingData(false);
      console.error('Error adding new question:', error);
      setQuestionUpdateMessage({ text: 'An error occurred while adding the new question.', isError: true });
    }
  };

  return (
    <div>
      {(loadingData) && <Loader />}
      <div className="edit-container-questions">
        <div className="edit-inner-container">
          <div className="add-question-button-container">
            <button className="add-question-button" onClick={() => setShowAddQuestionPopup(true)}>
              +
            </button>
          </div>
          <h2>Edit Questions</h2>

          {/* Display the message here */}
          {questionUpdateMessage.text && (
            <p className={`message ${questionUpdateMessage.isError ? 'error' : ''}`}>
              {questionUpdateMessage.text}
            </p>
          )}

          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="questions">
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {questionsData.map((question, index) => (
                    <Draggable
                      key={question.id || index}
                      draggableId={question.id || `${index}`}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          className="question-row"
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          <div className="drag-handle" {...provided.dragHandleProps}>
                            ⋮⋮⋮
                          </div>
                          <div className="question-content">
                            <div className="question-label">Question {index + 1}:</div>
                            <input
                              type="text"
                              value={question.text}
                              onChange={(e) => handleQuestionChange(index, 'text', e.target.value)}
                              className="question-input"
                              placeholder="Question text"
                            />
                            <div className="answer-row">
                              <input
                                type="text"
                                placeholder="Answers (comma-separated)"
                                value={
                                  question.answersString !== undefined
                                    ? question.answersString
                                    : question.answers.join(', ')
                                }
                                onChange={(e) =>
                                  handleQuestionChange(index, 'answersString', e.target.value)
                                }
                                className="answer-input"
                                style={{ width: '60%' }}
                              />

                              {/* Container for label and checkbox */}
                              <div className="negative-toggle-container" style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
                                <label className="negative-label" style={{ marginRight: '5px' }}>Negative:</label>
                                <input
                                  type="checkbox"
                                  checked={question.isNegative}
                                  onChange={(e) => handleQuestionChange(index, 'isNegative', e.target.checked)}
                                  className="negative-toggle"
                                />
                              </div>

                              <div className="question-buttons" style={{ marginLeft: '20px' }}> {/* Adjust spacing here */}
                                <button className="save-button" onClick={() => saveQuestion(question, index)}>
                                  Save
                                </button>
                                <button
                                  className="delete-question-button"
                                  onClick={() => deleteQuestion(index)}
                                >
                                  Delete
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>

        {/* Delete Question Confirmation Popup */}
        {showQuestionDeletePopup && (
          <div className="popup-overlay">
            <div className="popup">
              <p className="popup-text">Delete this question? This cannot be undone.</p>
              <button className="cancel-button" onClick={() => setShowQuestionDeletePopup(false)}>
                Cancel
              </button>
              <button className="delete-button" onClick={handleDeleteQuestionConfirm}>
                Delete
              </button>
            </div>
          </div>
        )}

        {/* Add New Question Popup */}
        {showAddQuestionPopup && (
          <div className="popup-overlay">
            <div className="popup">
              <p className="h2-white">Add New Question</p>
              <div className="form-group">
                <input
                  type="text"
                  placeholder="Question"
                  value={newQuestionText}
                  onChange={(e) => setNewQuestionText(e.target.value)}
                  className="popup-input"
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  placeholder="Answers - comma separated"
                  value={newQuestionAnswers}
                  onChange={(e) => setNewQuestionAnswers(e.target.value)}
                  className="popup-input"
                />
              </div>
              <div className="form-group">
                <p className="tinyLabel">Index Number (where to insert the question)</p>
                <input
                  type="number"
                  placeholder={`Index (0 to ${questionsData.length})`}
                  value={newQuestionIndex}
                  onChange={(e) => setNewQuestionIndex(e.target.value)}
                  className="popup-input"
                  min="0"
                  max={questionsData.length}
                />
              </div>
              <div className="popup-button-container">
                <button className="popup-cancel-button" onClick={() => setShowAddQuestionPopup(false)}>
                  Cancel
                </button>
                <button className="popup-save-button" onClick={addNewQuestion}>
                  Save
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default EditQuestions;