import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getFunctions } from 'firebase/functions';

const firebaseConfig = {
  apiKey: "AIzaSyCGmMqE8LwMPjTh8xRGURUnZCosdszlloE",
  authDomain: "vallotton-app.firebaseapp.com",
  projectId: "vallotton-app",
  storageBucket: "vallotton-app.appspot.com",
  messagingSenderId: "199022944560",
  appId: "1:199022944560:web:ae616eca31fbca8eb828f5",
  measurementId: "G-LBCRFJWZDF"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const functions = getFunctions(app); 

export { app, db, auth, functions };
