import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

// Styled component for the Back button
const BackButton = styled.button`
  position: fixed;
  top: 20px; /* Adjust position for top-left corner */
  left: 20px;
  z-index: 100; /* Ensure it's above other elements */
  font-family: 'Roboto Slab', serif;
  font-weight: 900;
  font-size: 18px;
  padding: 10px 20px;
  background-color: transparent;
  color: var(--color_4); /* Or any color you prefer */
  border: none;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  
  /* Add border-radius for pill shape */
  border-radius: 50px;
  
  &:hover {
    background-color: var(--color_4); /* Hover background color */
    color: #ffffff; /* Hover text color */
  }

  .triangle {
    display: inline-block;
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-right: 10px solid currentColor; /* Left-pointing triangle */
    margin-right: 7px; /* Space between triangle and text */
    position: relative;
    top: 1px;
  }
`;

const BackToLandingButton = () => {
  const navigate = useNavigate();  // Hook to navigate between routes

  const handleBackClick = () => {
    navigate('/landing-page');  // Navigate to /landing-page
  };

  return (
    <BackButton onClick={handleBackClick}>
      <div className="triangle"></div> {/* Left-pointing triangle */}
      Back
    </BackButton>
  );
};

export default BackToLandingButton;