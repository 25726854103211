// components/Builder/EditAssessment.js
import React, { useState } from 'react';
import { doc, updateDoc, deleteDoc, arrayRemove, collection, getDocs } from 'firebase/firestore';
import { db } from '../../auth/firebase';
import PillButton from '../../ui/PillButton';

const EditAssessment = ({
  assessmentData,
  setAssessmentData,
  setMessage,
  updateMessage,
  setUpdateMessage,
}) => {
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [deleteConfirmationText, setDeleteConfirmationText] = useState('');

  // Handle input changes for assessment fields
  const handleAssessmentChange = (e) => {
    const { name, value, type, checked } = e.target;
    setAssessmentData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  // Function to update assessment in Firebase
  const updateAssessment = async () => {
    if (!assessmentData) return;
    try {
      const assessmentDocRef = doc(db, 'assessments', assessmentData.id);
      await updateDoc(assessmentDocRef, {
        title: assessmentData.title,
        description: assessmentData.description,
        isLocked: assessmentData.isLocked,
        version: assessmentData.version,
      });
      setUpdateMessage('Assessment updated successfully!');
    } catch (error) {
      console.error('Error updating assessment:', error);
      setUpdateMessage('An error occurred while updating the assessment.');
    }
  };

  // Function to handle deletion
  const handleDeleteAssessment = async () => {
    if (deleteConfirmationText !== assessmentData.id) {
      setMessage('Assessment name does not match.');
      return;
    }
  
    try {
      // Delete associated questions
      for (const questionRef of assessmentData.questions) {
        const questionDocRef = doc(db, questionRef);
        await deleteDoc(questionDocRef);
      }
  
      // Extra cleanup step: Delete any extraneous questions based on url-title pattern
      const assessmentUrlPrefix = `${assessmentData['url-title']}-`; // Prefix for extraneous questions
  
      // Query the questions collection to find any extraneous questions
      const questionsQuerySnapshot = await getDocs(collection(db, 'questions'));
      questionsQuerySnapshot.forEach(async (docSnapshot) => {
        const questionId = docSnapshot.id;
  
        // Check if the question ID starts with the assessment's url-title followed by a dash and a number
        if (questionId.startsWith(assessmentUrlPrefix) && /\d+$/.test(questionId)) {
          const questionDocRef = doc(db, 'questions', questionId);
          await deleteDoc(questionDocRef); // Delete the extraneous question
        }
      });
  
      // Delete the assessment document itself
      const assessmentDocRef = doc(db, 'assessments', assessmentData.id);
      await deleteDoc(assessmentDocRef);
  
      // Construct the URL for the assessment using "url-title"
      const assessmentUrl = `assessments/${assessmentData['url-title']}`;
  
      // Remove assessment from parent assessmentCollection
      const assessmentCollectionRef = doc(db, assessmentData.assessmentCollection);
      await updateDoc(assessmentCollectionRef, {
        assessments: arrayRemove(assessmentUrl), // Correctly removing the constructed assessment URL
      });
  
      setMessage('Assessment and questions deleted successfully!');
      setAssessmentData(null);
      setShowDeletePopup(false);
      setDeleteConfirmationText('');
    } catch (error) {
      console.error('Error deleting assessment:', error);
      setMessage('An error occurred while deleting the assessment.');
    }
  };

  return (
    <div className="edit-container">
      <div className="edit-inner-container">
        <div className="delete-button-container">
          <button className="delete-button" onClick={() => setShowDeletePopup(true)}>
            Delete
          </button>
        </div>
        <h2>Edit Assessment</h2>
        <div className="form-group">
          <label className="form-label">Title:</label>
          <input
            type="text"
            name="title"
            value={assessmentData.title}
            onChange={handleAssessmentChange}
            className="input"
          />
        </div>
        <div className="form-group">
          <label className="form-label">Description:</label>
          <input
            type="text"
            name="description"
            value={assessmentData.description}
            onChange={handleAssessmentChange}
            className="input"
          />
        </div>
        <div className="form-group">
          <label className="form-label">Version:</label>
          <input
            type="text"
            name="version"
            value={assessmentData.version}
            onChange={handleAssessmentChange}
            className="input"
          />
        </div>
        <div className="form-group">
          <label className="form-label">
            Is Locked:
            <input
              type="checkbox"
              name="isLocked"
              checked={assessmentData.isLocked}
              onChange={handleAssessmentChange}
              className="checkbox"
            />
          </label>
        </div>

        <PillButton theme="dark" className="narrow" type="submit" size="small" onClick={updateAssessment}>
          Update
          <span className="triangle"></span>
        </PillButton>

        {updateMessage && <p className="update-message">{updateMessage}</p>}
      </div>

      {/* Delete Assessment Confirmation Popup */}
      {showDeletePopup && (
        <div className="popup-overlay">
          <div className="popup">
            <p className="popup-text">
              Are you sure you want to delete this assessment and all of its questions? This cannot be undone.
            </p>
            <div className="form-group">
              <label className="popup-label">
                Type assessment name to delete it.
              </label>
              <div className="delete-input-container">
                <input
                  type="text"
                  placeholder={assessmentData.id}
                  value={deleteConfirmationText}
                  onChange={(e) => setDeleteConfirmationText(e.target.value)}
                  className="delete-input"
                />
                
              </div>
            </div>
            <button className="inner-cancel-button" onClick={() => setShowDeletePopup(false)}>
              Cancel
            </button>
            <button className="delete-button" onClick={handleDeleteAssessment}>
              Delete
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditAssessment;