import { doc, getDoc, setDoc, collection } from 'firebase/firestore';
import { db } from '../auth/firebase';

// Function to initialize progresses in the user subcollection
export const initializeAssessmentProgresses = async (user) => {
    console.log('Starting initializeAssessmentProgresses for user:', user.uid);
    try {
        // Get the reference to the assessments collection
        const assessmentCollectionRef = doc(db, 'assessmentCollection', 'marriage-connection-assessment');
        console.log('Attempting to fetch assessment collection');
        const assessmentCollectionSnapshot = await getDoc(assessmentCollectionRef);

        if (assessmentCollectionSnapshot.exists()) {
            console.log('Assessment collection found');
            const { assessments: assessmentRefs } = assessmentCollectionSnapshot.data();
            console.log('Number of assessment references:', assessmentRefs.length);
            
            console.log('Fetching individual assessments');
            const fetchedAssessments = await Promise.all(
                assessmentRefs.map(async (ref) => {
                    console.log('Fetching assessment with ref:', ref.path);
                    const assessmentDoc = await getDoc(ref);
                    if (assessmentDoc.exists()) {
                        console.log('Assessment found:', assessmentDoc.id);
                        return {
                            id: assessmentDoc.id,
                            ...assessmentDoc.data(),
                        };
                    } else {
                        console.error('Assessment not found for ref:', ref.path);
                        return null;
                    }
                })
            );
            
            console.log('Number of fetched assessments:', fetchedAssessments.filter(Boolean).length);
            
            // Subcollection for the user's progresses
            const userProgressesCollectionRef = collection(db, `users/${user.uid}/progresses`);
            
            console.log('Initializing individual assessment progresses');
            // Store each progress in the user's subcollection
            await Promise.all(
                fetchedAssessments.filter(Boolean).map(async (assessment, index) => {
                    const progressDocRef = doc(userProgressesCollectionRef, assessment.id);
                    console.log(`Checking progress for assessment: ${assessment.title}`);
                    
                    // Check if the assessment progress document already exists
                    const progressDocSnapshot = await getDoc(progressDocRef);
                    if (!progressDocSnapshot.exists()) {
                        console.log(`Initializing new progress for: ${assessment.title}`);
                        // Create the individual progress documents if they don't exist
                        await setDoc(progressDocRef, {
                            'url-title': assessment['url-title'],
                            title: assessment.title,
                            index: assessment.index,
                            status: index === 0 ? 1 : 0, // First assessment unlocked, the rest locked
                            'question-index': 0,
                            assessment: doc(db, 'assessments', assessment.id), // Reference to the assessment
                            uid: user.uid,
                        });
                        console.log(`Initialized progress for: ${assessment.title}`);
                    } else {
                        console.log(`Progress for ${assessment.title} already exists, skipping initialization.`);
                    }
                })
            );
            
            console.log('All progresses initialized successfully.');
        } else {
            console.error('Assessment collection not found.');
        }
    } catch (error) {
        console.error('Error initializing progresses:', error);
        console.error('Error stack:', error.stack);
    }
};