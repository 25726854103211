import React from 'react';
import Header from './Header';
import { useConsolidatedUser } from '../contexts/ConsolidatedUserContext';
import PillButton from '../ui/PillButton';
import './contact-page.css'

const ContactPage = () => {
  const { currentUser, userData, logout } = useConsolidatedUser(); // Get the required data from context

  // Handle the logout action
  const handleLogout = async () => {
    try {
      await logout();
      // Optionally redirect or show a success message
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  return (
    <>
      <Header />

      <div className="outer-container">
        <div className="contact-container">
          <h1>Contact us!</h1>
          <p>Having trouble with the app? Let us know about it! We'll take care the problem as soon as we can. Please be as specific and detailed as possible if you're reporting a bug in the system.</p>
          <p>Have some positive feedback? We'd love to hear that too!</p>
          <h1></h1>
          <PillButton
            theme="dark"
            className="medium"
            type="button"
            onClick={() => window.location.href = 'mailto:support@vallotton.app'} k
          >
            Support
            <span className="triangle"></span>
          </PillButton>
        </div>
      </div>




    </>
  );
};

export default ContactPage;