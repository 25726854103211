import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useConsolidatedUser } from '../../contexts/ConsolidatedUserContext';
import './login-form.css';
import '../../index.css';
import PillButton from '../../ui/PillButton';
import Loader from '../../ui/Loader';
import { db } from '../../auth/firebase';
import { collection, addDoc, getDocs, query, where } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';
import Header from '../Header';


const RequestAccess = () => {
    const { error, setError } = useConsolidatedUser();
    const [firstName1, setFirstName1] = useState('');
    const [email1, setEmail1] = useState('');
    const [firstName2, setFirstName2] = useState('');
    const [email2, setEmail2] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [loading, setLoading] = useState(false); // Updated loading state
    const [message, setMessage] = useState(null);
    const navigate = useNavigate(); // For navigation
    const { currentUser, userData, handleLogout } = useConsolidatedUser();

    // Function to generate a UUID for the couple code
    const generateCoupleCode = () => uuidv4();

    // Function to generate a secure access token for email access
    const generateAccessToken = async () => {
        const array = new Uint8Array(32); // 32 bytes
        window.crypto.getRandomValues(array); // Fill with cryptographically strong random values
        return Array.from(array, byte => ('0' + byte.toString(16)).slice(-2)).join(''); // Convert to hex
    };

    const addPreUser = async (preUser) => {
        await addDoc(collection(db, 'preUsers'), preUser);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setMessage(null);
        setIsSubmitting(true);
        setLoading(true); // Show loader

        // Validate emails
        if (!isValidEmail(email1) || !isValidEmail(email2)) {
            setError('Please enter valid email addresses.');
            setIsSubmitting(false);
            setLoading(false); // Hide loader
            return;
        }

        try {
            // Check if either email already exists in preUsers
            const preUsersRef = collection(db, 'preUsers');
            const q = query(preUsersRef, where('email', 'in', [email1, email2]));
            const querySnapshot = await getDocs(q);

            console.log ('Checking to see if users already exist.');

            if (!querySnapshot.empty) {
                console.log ('Users emails do already exist!');
                setError('One or both of the provided emails already exists in our system.');
                setIsSubmitting(false);
                setLoading(false);
                return;
            }

            console.log ('Users emails do not already exist!');

            // Generate couple code and access tokens
            const coupleCode = generateCoupleCode();
            const accessToken1 = await generateAccessToken();
            const accessToken2 = await generateAccessToken();

            // Create pre-user objects
            const preUsers = [
                {
                    email: email1,
                    firstName: firstName1,
                    coupleCode: coupleCode,
                    accessToken: accessToken1,
                },
                {
                    email: email2,
                    firstName: firstName2,
                    coupleCode: coupleCode,
                    accessToken: accessToken2,
                },
            ];

            // Add pre-users to Firestore and call Cloud Function to send invitation emails
            const promises = preUsers.map(async (preUser) => {
                await addPreUser(preUser);
                await sendInviteEmail(preUser.email, preUser.accessToken, preUser.firstName);
            });

            await Promise.all(promises);

            // On success, navigate to confirmation page
            navigate('/email-confirmation');
        } catch (err) {
            console.error('Error checking pre-users or adding pre-users:', err);
            setError('An error occurred while requesting access. Please try again.');
            navigate('/error', { state: { errorMessage: 'Failed to send invite emails.' } });
        } finally {
            setIsSubmitting(false);
            setLoading(false);
        }
    };

    const sendInviteEmail = async (email, accessToken, firstName) => {
        const url = "https://us-central1-vallotton-app.cloudfunctions.net/sendInviteEmail";

        const headers = {
            "Content-Type": "application/json"
        };

        const body = JSON.stringify({
            email,
            accessToken,
            firstName,
        });

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: headers,
                body: body,
            });

            if (response.ok) {
                const result = await response.json();
                console.log("Email sent successfully!", result);
            } else {
                console.error("Error sending email", response.status, response.statusText);
                throw new Error('Failed to send invite email.');
            }
        } catch (error) {
            console.error("Error in the request:", error);
            throw error;
        }
    };

    const resetFormFields = () => {
        setFirstName1('');
        setEmail1('');
        setFirstName2('');
        setEmail2('');
    };

    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    return (
        <div>
            <Header
                firstName={userData?.firstName || currentUser?.email || 'User'}
                onLogout={handleLogout}
                isAssessmentPage={false}
            />

            <div className="login-form-container">
                {loading ? (
                    <Loader /> // Show loader while submitting
                ) : (
                    <>
                        <form onSubmit={handleSubmit} className="login-form">
                            <h2 className="auth-title">Request Access</h2>
                            <div className="why">We gather information for both partners<br></br>to safely link your accounts.</div>
                            <hr className="custom-hr"></hr>
                            {error && <p className="error-message">{error}</p>}
                            {message && <p style={{ color: 'green' }}>{message}</p>}
                            <h3 style={{ color: 'gray' }}>You</h3>
                            <input
                                type="text"
                                placeholder="First Name"
                                value={firstName1}
                                onChange={(e) => {
                                    setFirstName1(e.target.value);
                                    setError(null);
                                }}
                                required
                            />
                            <input
                                type="email"
                                placeholder="Email"
                                value={email1}
                                onChange={(e) => {
                                    setEmail1(e.target.value);
                                    setError(null);
                                }}
                                required
                            />
                            <h3 style={{ color: 'gray' }}>Your Spouse</h3>
                            <input
                                type="text"
                                placeholder="First Name"
                                value={firstName2}
                                onChange={(e) => {
                                    setFirstName2(e.target.value);
                                    setError(null);
                                }}
                                required
                            />
                            <input
                                type="email"
                                placeholder="Email"
                                value={email2}
                                onChange={(e) => {
                                    setEmail2(e.target.value);
                                    setError(null);
                                }}
                                required
                            />
                            <PillButton theme="light" className="wide" type="submit" disabled={isSubmitting}>
                                Request Access
                                <span className="triangle"></span>
                            </PillButton>
                        </form>
                    </>
                )}
            </div>
        </div>
    );
};

export default RequestAccess;