import React, { useState, useEffect } from 'react';
import { useConsolidatedUser } from '../../contexts/ConsolidatedUserContext';
import { useNavigate } from 'react-router-dom';
import './login-form.css';
import '../../index.css';
import logo from '../../assets/marriage-connection-logo.png';
import PillButton from '../../ui/PillButton';
import Loader from '../../ui/Loader';
import Header from '../Header';
import { linkSpouses } from '../../services/LinkSpouses';

const getErrorMessage = (errorCode) => {
  switch (errorCode) {
    case 'auth/email-already-in-use':
      return 'This email is already in use. Please try logging in instead.';
    case 'auth/invalid-email':
      return 'Please enter a valid email address.';
    case 'auth/weak-password':
      return 'Your password is too weak. Please choose a stronger password.';
    case 'auth/wrong-password':
      return 'Incorrect password. Please try again.';
    case 'auth/user-not-found':
      return 'No account found with this email. Please sign up first.';
    default:
      return 'An unexpected error occurred. Please try again.';
  }
};

const LoginForm = () => {

  console.log('Login: -- Starting login process.');

  const { signIn, sendPasswordReset, loading, error: contextError, setError, currentUser } = useConsolidatedUser();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState(null);
  const navigate = useNavigate();
  const { handleLogout, userData } = useConsolidatedUser();

  // Email validation function
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage(null);
    setError(null);
    setIsSubmitting(true);

    if (!isValidEmail(email)) {
      setError('Please enter a valid email address.');
      setIsSubmitting(false);
      return;
    }

    try {
      const userCredential = await signIn(email, password);

      if (!userCredential || !userCredential.user) {
        throw new Error("Failed to log in. Please try again.");
      }

      console.log('Login: -- Checking for linked spouse.');
      await linkSpouses(userCredential.user);

      console.log('Login: -- User logged in:', userCredential.user);
      navigate('/home-page');

    } catch (err) {
      console.error('Error during login:', err);
      if (err.code) {
        const errorMessage = getErrorMessage(err.code);
        setError(errorMessage);
      } else {
        setError(err.message || 'An unexpected error occurred. Please try again.');
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const handlePasswordReset = async () => {
    if (!isValidEmail(email)) {
      setError('Please enter a valid email address to reset your password.');
      return;
    }

    try {
      console.log('Login: -- Starting password reset flow.');
      await sendPasswordReset(email);
      setMessage('Password reset email sent. Please check your inbox.');
    } catch (error) {
      console.error('Error during password reset:', error);
      setError('Failed to send password reset email. Please try again.');
    }
  };

  return (
    <div>
      <Header />

      <div className="login-form-container">
        {isSubmitting ? <Loader /> : (
          <>
            <form onSubmit={handleSubmit} className="login-form">
              <h2 className="login-title">Welcome!</h2>
              {contextError && <p className="error-message">{contextError}</p>}
              {message && <p style={{ color: 'green' }}>{message}</p>}
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setError(null);
                }}
                required
              />
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  setError(null);
                }}
                required
              />

              <PillButton theme="light" className="wide" type="submit" disabled={isSubmitting}>
                Sign In
                <span className="triangle"></span>
              </PillButton>
            </form>

            <p className="forgot-password">
              Forgot your password?{' '}
              <span onClick={handlePasswordReset} className="reset-link">
                Click here to reset it.
              </span>
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default LoginForm;