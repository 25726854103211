// components/Builder/AssessmentBuilder.js
import React, { useState, useEffect } from 'react';
import { doc, setDoc, getDoc, updateDoc, arrayUnion, getDocs, collection, writeBatch, DocumentReference } from 'firebase/firestore';
import { db } from '../../auth/firebase';
import { getAuth } from 'firebase/auth';
import EditAssessment from './EditAssessment';
import EditQuestions from './EditQuestions';
import './assessment-builder.css';
import PillButton from '../../ui/PillButton';
import { useConsolidatedUser } from '../../contexts/ConsolidatedUserContext';
import Header from '../Header';
import Loader from '../../ui/Loader';

const AssessmentBuilder = () => {
  // State variables
  const { currentUser, userData, loading, logout } = useConsolidatedUser();
  const [jsonInput, setJsonInput] = useState('');
  const [message, setMessage] = useState('');
  const [assessmentData, setAssessmentData] = useState(null);
  const [updateMessage, setUpdateMessage] = useState('');
  const [questionsData, setQuestionsData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [assessments, setAssessments] = useState([]);

  useEffect(() => {
    const fetchAssessments = async () => {
      setLoadingData(true);
      try {
        const assessmentCollectionRef = doc(db, 'assessmentCollection', 'marriage-connection-assessment');
        const assessmentSnap = await getDoc(assessmentCollectionRef);

        if (assessmentSnap.exists()) {
          const data = assessmentSnap.data();
          const assessmentRefs = data.assessments;

          // Get the assessments referenced
          const assessmentPromises = assessmentRefs.map(async (assessmentRef) => {
            const assessmentDoc = await getDoc(assessmentRef);
            return assessmentDoc.exists() ? assessmentDoc.data() : null;
          });

          const assessmentsData = await Promise.all(assessmentPromises);
          setAssessments(assessmentsData.filter(Boolean)); // Set valid assessments
        } else {
          console.error('Parent assessment collection not found.');
        }
      } catch (error) {
        console.error('Error fetching assessments:', error);
      } finally {
        setLoadingData(false);
      }
    };

    fetchAssessments();
  }, []);

  // Function to handle JSON submission
  const handleSubmit = async () => {

    const inputTrimmed = jsonInput.trim();

    setLoadingData(true);

    if (jsonInput.trim() === '') {
      setMessage('Please enter JSON data or an assessment name.');
      return;
    }

    if (inputTrimmed.startsWith('clean questions:')) {
      const assessmentName = inputTrimmed.split(':')[1].trim(); // Extract the assessment name

      if (!assessmentName) {
        setMessage('Please specify a valid assessment name after "clean questions:"');
        return;
      }

      setLoadingData(true);
      await cleanUpQuestionsRefs(assessmentName);  // Pass assessmentName to the cleanup function
      setLoadingData(false);
      return;
    }

    if (inputTrimmed.startsWith('clean assessment:')) {
      const assessmentName = inputTrimmed.split(':')[1].trim(); // Extract the assessment name

      if (!assessmentName) {
        setMessage('Please specify a valid assessment name after "clean assessment:"');
        return;
      }

      setLoadingData(true);
      await cleanUpQuestionsRefs(db, assessmentName);  // Pass assessmentName to the cleanUpQuestionsRefs function
      setLoadingData(false);
      return;
    }

    if (jsonInput.trim() === 'clean assessments') {
      setLoadingData(true);
      await cleanUpQuestionAssessmentRefs();
      setLoadingData(false);
    }

    // Check if input is an assessment name
    if (isAssessmentName(jsonInput)) {
      await fetchAssessment(jsonInput.trim());
      setLoadingData(false);
    } else {
      try {
        const data = JSON.parse(jsonInput);
        const assessment = data.assessment;
        const assessmentUrlTitle = assessment['url-title'];
        const assessmentDocRef = doc(db, 'assessments', assessmentUrlTitle);

        console.log('xxxxxxx - Processing: ', assessmentUrlTitle);

        // Create the question references array as we create question documents
        const questionRefs = [];

        for (const question of assessment.questions) {
          const questionId = `${assessmentUrlTitle}-${question.index}`;
          const questionDocRef = doc(db, 'questions', questionId);

          console.log('xxxxxxx - creating: ', questionId);

          // Set the 'assessment' field in the question as a Firestore document reference
          question.assessment = assessmentDocRef;  // Store the assessment document reference

          // Create the question document in Firebase
          await setDoc(questionDocRef, question);

          console.log('xxxxxxx - pushing: ', questionId);

          // Add the question document reference (not a string) to the array
          questionRefs.push(questionDocRef);  // Store the document reference directly
        }

        console.log('xxxxxxx - setting questions in object');

        // Update the assessment object with question document references
        assessment.questions = questionRefs;

        console.log('xxxxxxx - creating assessment doc in firebase');

        // Create the assessment document in Firebase
        await setDoc(assessmentDocRef, assessment);

        console.log('xxxxxxx - updating the parent ref: ', assessment.assessmentCollection);

        // Update the parent assessmentCollection
        const assessmentCollectionRef = doc(db, assessment.assessmentCollection);
        const assessmentCollectionSnap = await getDoc(assessmentCollectionRef);

        if (assessmentCollectionSnap.exists()) {
          console.log('xxxxxxx - Got doc and are editing');

          await updateDoc(assessmentCollectionRef, {
            assessments: arrayUnion(assessmentDocRef),  // Use the document reference directly
          });
        } else {
          console.log('xxxxxxx - Doc does not exist and is being created');

          // If the assessmentCollection doesn't exist, create it
          await setDoc(assessmentCollectionRef, {
            assessments: [assessmentDocRef],  // Store the assessment document reference
          });
        }

        console.log('xxxxxxx - Successful creation of the assessment');
        setMessage('Assessment and questions created successfully!');
        setJsonInput(''); // Clear input
        setLoadingData(false);
      } catch (error) {
        setLoadingData(false);
        console.error('Error creating assessment:', error);
        setMessage('An error occurred. Please check the console for details.');
      }
    }
  };

  // Function to check if the input is an assessment name starting with 'mca-'
  const isAssessmentName = (input) => {
    const trimmedInput = input.trim();
    return trimmedInput.startsWith('mca-');
  };

  // Function to fetch assessment data
  const fetchAssessment = async (assessmentDocRef) => {
    try {
      const assessmentSnap = await getDoc(assessmentDocRef);
      if (assessmentSnap.exists()) {
        const data = assessmentSnap.data();
        const questionRefs = data.questions;

        setAssessmentData({
          id: assessmentDocRef.id,
          ...data,
        });

        // Fetch questions
        const fetchedQuestions = [];
        for (const questionRef of questionRefs) {
          const questionSnap = await getDoc(questionRef);
          if (questionSnap.exists()) {
            fetchedQuestions.push({
              id: questionSnap.id,
              ...questionSnap.data(),
            });
          }
        }

        fetchedQuestions.sort((a, b) => a.index - b.index);
        setQuestionsData(fetchedQuestions);
        setMessage('');
        setUpdateMessage('');
      } else {
        setMessage('Assessment not found.');
        setAssessmentData(null);
        setQuestionsData([]);
      }
    } catch (error) {
      console.error('Error fetching assessment:', error);
      setMessage('An error occurred while fetching the assessment.');
    }
  };

  const handleFetchAssessmentFromRef = async (assessmentRef) => {
    try {
      const assessmentSnap = await getDoc(assessmentRef);

      if (assessmentSnap.exists()) {
        const data = assessmentSnap.data();
        const assessmentUrlTitle = data['url-title'];
        const assessmentDocRef = doc(db, 'assessments', assessmentUrlTitle);

        console.log('Processing Assessment:', assessmentUrlTitle);

        // Create the question references array as we create question documents
        const questionRefs = [];

        for (const questionRef of data.questions) {
          // Fetch each question document referenced in the assessment
          const questionSnap = await getDoc(questionRef);
          if (questionSnap.exists()) {
            const questionData = questionSnap.data();
            const questionId = `${assessmentUrlTitle}-${questionData.index}`;
            const questionDocRef = doc(db, 'questions', questionId);

            console.log('Creating question:', questionId);

            // Set the 'assessment' field in the question as a Firestore document reference
            questionData.assessment = assessmentDocRef;

            // Create or update the question document in Firebase
            await setDoc(questionDocRef, questionData);

            console.log('Pushing question:', questionId);

            // Add the question document reference to the array
            questionRefs.push(questionDocRef);
          } else {
            console.error('Question not found for reference:', questionRef);
          }
        }

        console.log('Setting questions in assessment object');

        // Update the assessment object with question document references
        data.questions = questionRefs;

        console.log('Creating assessment document in Firebase');

        // Create or update the assessment document in Firebase
        await setDoc(assessmentDocRef, data);

        console.log('Updating the parent assessmentCollection:', data.assessmentCollection);

        // Update the parent assessmentCollection
        const assessmentCollectionRef = doc(db, data.assessmentCollection);
        const assessmentCollectionSnap = await getDoc(assessmentCollectionRef);

        if (assessmentCollectionSnap.exists()) {
          console.log('Editing parent assessment collection');

          await updateDoc(assessmentCollectionRef, {
            assessments: arrayUnion(assessmentDocRef),
          });
        } else {
          console.log('Creating parent assessment collection document');

          // If the assessmentCollection doesn't exist, create it
          await setDoc(assessmentCollectionRef, {
            assessments: [assessmentDocRef],
          });
        }

        console.log('Successful creation and linking of the assessment');
        setMessage('Assessment and questions created successfully!');
      } else {
        setMessage('Assessment not found.');
      }
    } catch (error) {
      console.error('Error fetching and creating assessment:', error);
      setMessage('An error occurred. Please check the console for details.');
    }
  };


  const cleanUpQuestionsRefs = async (assessmentName) => {
    try {
      // Reference to the specific assessment document
      const assessmentDocRef = doc(db, 'assessments', assessmentName);
      const assessmentSnap = await getDoc(assessmentDocRef);

      if (!assessmentSnap.exists()) {
        console.error(`Assessment "${assessmentName}" not found.`);
        return;
      }

      const assessmentData = assessmentSnap.data();
      const { questions } = assessmentData;

      if (!questions || !Array.isArray(questions)) {
        console.error(`No questions found for assessment "${assessmentName}".`);
        return;
      }

      const batch = writeBatch(db); // Initialize Firestore batch

      // Iterate over questions and convert strings to references if needed
      const questionRefs = questions.map((question) => {
        if (typeof question === 'string') {
          // Convert string to Firestore DocumentReference
          const segments = question.split('/');
          if (segments.length === 2) {
            return doc(db, segments[0], segments[1]); // Convert valid string path to Firestore DocumentReference
          } else {
            console.error(`Invalid Firestore path: ${question}`);
            return null;
          }
        } else if (question instanceof DocumentReference) {
          return question; // Already a reference, no need to change
        } else {
          console.error(`Invalid question format: ${question}`);
          return null; // Handle invalid format
        }
      }).filter(Boolean); // Remove any null refs

      // Update the assessment document with cleaned question references
      batch.update(assessmentDocRef, { questions: questionRefs });

      // Commit the batch to Firestore
      await batch.commit();

      console.log(`Questions for "${assessmentName}" successfully converted to references.`);
    } catch (error) {
      console.error('Error updating questions for assessment:', error);
    }
  };



  const cleanUpQuestionAssessmentRefs = async (db, assessmentName) => {
    try {
      console.log(`Start Clean-Up of AssessmentRefs inside Questions for assessment: ${assessmentName}`);

      // Get the specific assessment questions collection
      const questionsCollectionRef = collection(db, 'questions');
      const questionsSnapshot = await getDocs(questionsCollectionRef);

      const batch = writeBatch(db); // Initialize a Firestore batch
      console.log(`Initialized Batch`);

      // Iterate over each question document
      questionsSnapshot.forEach((questionDoc) => {
        const questionData = questionDoc.data();
        const assessment = questionData.assessment; // Get the current "assessment" field

        // Log the value of the "assessment" field before attempting any conversion
        console.log(`Processing assessment field for question ${questionDoc.id}:`, assessment);

        // Check if the assessment is a string and if it's a valid Firestore path
        if (typeof assessment === 'string') {
          // Extra validation to ensure the string has the correct format for Firestore paths
          if (assessment.split('/').length === 2) {
            try {
              // Convert the string URL to a Firestore reference
              const assessmentRef = doc(db, assessment); // Convert to a Firestore document ref

              // Add this update to the batch
              const questionRef = doc(db, 'questions', questionDoc.id);
              batch.update(questionRef, { assessment: assessmentRef }); // Update the "assessment" field
            } catch (conversionError) {
              console.error(`Error converting assessment string "${assessment}" to reference:`, conversionError);
            }
          } else {
            // Log if the string doesn't seem to be a valid Firestore path
            console.warn(`Invalid Firestore path format in assessment field for question ${questionDoc.id}:`, assessment);
          }
        } else if (assessment instanceof DocumentReference) {
          // It's already a Firestore DocumentReference, no need to change it
          console.log(`Assessment is already a DocumentReference for question ${questionDoc.id}`);
        } else {
          // Log if the assessment field is not a string or DocumentReference (unexpected type)
          console.warn(`Skipping invalid assessment field for question ${questionDoc.id}:`, assessment);
        }
      });

      // Commit the batch to Firestore
      await batch.commit();

      console.log(`Assessment field successfully converted to references in all questions for assessment: ${assessmentName}`);
    } catch (error) {
      console.error(`Error updating question assessment references for assessment: ${assessmentName}`, error);
    }
  };

  const handleQuickLinkClick = async (urlTitle) => {
    setLoadingData(true);
    try {
      const assessmentDocRef = doc(db, 'assessments', urlTitle);
      await fetchAssessment(assessmentDocRef);
    } catch (error) {
      console.error('Error fetching assessment:', error);
      setMessage('An error occurred while fetching the assessment.');
    } finally {
      setLoadingData(false);
    }
  };


  return (
    <div>

      {(loadingData) && <Loader />}

      <Header />
      <div className="container">
        <h1 className="title">Assessment Editor</h1>
        <textarea
          className="textarea"
          value={jsonInput}
          onChange={(e) => setJsonInput(e.target.value)}
          placeholder="Paste your JSON here or enter an assessment name"
        />
        <div className="button-container">
          <PillButton theme="dark" className="narrow" type="submit" onClick={handleSubmit}>
            Submit
            <span className="triangle"></span>
          </PillButton>
        </div>


        {/*
      <div>
        <button onClick={() => cleanUpQuestionsRefs(db)}>
          Clean Up Questions in Assessments Array (strings to refs)
        </button>
        <button onClick={() => cleanUpQuestionAssessmentRefs(db)}>
          Clean Up questions objects (assessment pointing strings to refs)
        </button>
        <button onClick={() => copyAllResultsToTopLevel()}>
          Copy user results to main collection
        </button>
      </div>
      */}


        {message && <p className="message">{message}</p>}

        {/* Editable Assessment Section */}
        {assessmentData && (
          <>
            <EditAssessment
              assessmentData={assessmentData}
              setAssessmentData={setAssessmentData}
              setMessage={setMessage}
              updateMessage={updateMessage}
              setUpdateMessage={setUpdateMessage}
            />
            <EditQuestions
              assessmentData={assessmentData}
              questionsData={questionsData}
              setQuestionsData={setQuestionsData}
              setUpdateMessage={setUpdateMessage}
            />
          </>
        )}
       <hr className="edit-hr"></hr>
        {/* Render quick links based on fetched assessments */}
        <div className="links-container">
          <div className="quick-links">
            {assessments.length > 0 && assessments.map((assessment, index) => (
              <p key={index} onClick={() => handleQuickLinkClick(assessment['url-title'])} className="assessment-link">
                {assessment.title} {/* Use the title as the text, url-title as value */}
              </p>
            ))}
          </div>
        </div>

      </div>
    </div>
  );
};

export default AssessmentBuilder;