import React from 'react';
import Lottie from 'react-lottie-player'; 
import emailAnimation from './email-lottie.json';

const EmailLottie = () => {
  return (
    <div className="email-lottie-container">
      <Lottie
        loop
        animationData={emailAnimation}
        play
        style={{ width: 150, height: 150 }}  // Customize the loader size
      />
    </div>
  );
};

export default EmailLottie;