import React from 'react';
import Header from './Header';
import { useConsolidatedUser } from '../contexts/ConsolidatedUserContext';

const LandingPage = () => {
  const { currentUser, userData, logout } = useConsolidatedUser(); // Get the required data from context

  // Handle the logout action
  const handleLogout = async () => {
    try {
      await logout();
      // Optionally redirect or show a success message
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  return (
    <>
      <Header />
      <iframe
        src="/landing-page/index.html"
        style={{ width: '100%', height: '100vh', border: 'none' }}
        title="Landing Page"
      ></iframe>
    </>
  );
};

export default LandingPage;