import React, { useEffect, useState } from 'react';
import AssessmentProcessor from './AssessmentProcessor';
import { doc, getDoc, query, collection, getDocs, where } from 'firebase/firestore';
import { db } from '../auth/firebase';
import { getAuth } from 'firebase/auth';
import './assessment-results.css';
import Header from './Header';
import Loader from '../ui/Loader';
import { useConsolidatedUser } from '../contexts/ConsolidatedUserContext';
import { useNavigate } from 'react-router-dom';
import '../index.css';
import AssessmentBlock from './AssessmentBlock'

const AssessmentResults = ({ assessmentCollection }) => {
    // State for results and loading
    const [results, setResults] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const { currentUser, userData, logout } = useConsolidatedUser();
    const [, setLogoutError] = useState(null);
    const navigate = useNavigate();
    const auth = getAuth();

    // State for spouse and flags
    const [, setSpouse] = useState(null);
    const [, setHasPartner] = useState(false);
    const [hasPartnerResult, setHasPartnerResult] = useState(false);
    const [spouseResults, setSpouseResults] = useState(null);
    const [, setUserAssessment] = useState(null);
    const [, setSpouseAssessment] = useState(null);
    const [spouseName, setSpouseName] = useState("");

    const fetchResultsByCouple = async (coupleCode) => {
        console.log('yyyyyyyyyy   Fetching by Couple')
        try {
            const resultsQuery = query(
                collection(db, "users"),
                where("coupleCode", "==", coupleCode)
            );

            console.log('yyyyyyyyyy   Querying')
            const querySnapshot = await getDocs(resultsQuery);

            console.log('yyyyyyyyyy   Docs count:', querySnapshot.docs.length)

            let results = [];
            for (const doc of querySnapshot.docs) {
                const userResultsRef = collection(db, `users/${doc.id}/results`);
                const userResultsSnapshot = await getDocs(userResultsRef);

                console.log('yyyyyyyyyy   Docs Iterate:', querySnapshot.docs.length)

                userResultsSnapshot.forEach((resultDoc) => {
                    results.push({
                        userId: doc.id,
                        resultId: resultDoc.id,
                        ...resultDoc.data()
                    });
                });
            }
            console.log('yyyyyyyyyy   RETURN -----> ', results)
            return results;
        } catch (error) {
            console.error("Error fetching results:", error);
            return null;
        }
    };

    useEffect(() => {

        console.log('yyyyyyyyyy   Run Assessment Outside')

        const runAssessment = async () => {
            try {

                console.log('yyyyyyyyyy   Run Assessment')
                setLoading(true);
                const currentUser = auth.currentUser;

                if (!currentUser) {
                    throw new Error('No user is currently authenticated.');
                }

                const userId = currentUser.uid;
                const userRef = doc(db, 'users', userId);
                const userDoc = await getDoc(userRef);

                if (!userDoc.exists()) {
                    throw new Error('User not found.');
                }

                console.log('yyyyyyyyyy   User Found')

                const coupleCode = userDoc.data().coupleCode;

                console.log('yyyyyyyyyy  CoupleCode: ', coupleCode);

                const currentResultRef = userDoc.data().currentResult;

                console.log('yyyyyyyyyy  FETCHING COUPLE')
                // Fetch results using couple code
                const coupleResults = await fetchResultsByCouple(coupleCode);

                console.log('yyyyyyyyyy  Fetched: ', coupleResults.size);

                if (coupleResults && coupleResults.length > 0) {
                    const userResult = coupleResults.find(result => result.userId === userId);
                    const spouseResult = coupleResults.find(result => result.userId !== userId);

                    console.log('yyyyyyyyyy  FETCHED > 0');

                    if (userResult) {
                        console.log('yyyyyyyyyy  User Results')
                        setResults(userResult);
                        if (userResult.processedAssessments && userResult.processedAssessments.length > 0) {
                            console.log('yyyyyyyyyy  SET USER ASSESSMENT');
                            setUserAssessment(userResult.processedAssessments[0]);
                        }
                    }

                    if (spouseResult) {
                        setSpouseResults(spouseResult);
                        setSpouse(spouseResult);
                        setHasPartner(true);
                        setHasPartnerResult(true);
                        if (spouseResult.processedAssessments && spouseResult.processedAssessments.length > 0) {
                            setSpouseAssessment(spouseResult.processedAssessments[0]);
                        }

                        const spouseDoc = await getDoc(doc(db, 'users', spouseResult.userId));
                        if (spouseDoc.exists()) {
                            setSpouseName(spouseDoc.data().firstName || "Spouse");
                        }
                    }
                }

                // Process assessments if there's no current result
                if (!currentResultRef) {
                    const processor = new AssessmentProcessor(userId, assessmentCollection);
                    const processResult = await processor.processAssessments();

                    if (processResult === "success") {
                        const updatedUserDoc = await getDoc(userRef);
                        const updatedCurrentResultRef = updatedUserDoc.data().currentResult;
                        if (updatedCurrentResultRef) {
                            await loadAndDisplayResults(updatedCurrentResultRef);
                        }
                    }
                } else {
                    console.log('yyyyyyyyyy  LOAD AND DISPLAY 1')
                    await loadAndDisplayResults(currentResultRef);
                }
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        runAssessment();
    }, [assessmentCollection, auth, setSpouse, setHasPartner, setSpouseAssessment, setUserAssessment]);

    const loadAndDisplayResults = async (resultRef) => {
        console.log('yyyyyyyyyy  LOAD AND DISPLAY 2')
        try {
            const resultDoc = await getDoc(resultRef);
            if (!resultDoc.exists()) {
                throw new Error('No results found.');
            }

            const resultData = resultDoc.data();
            setResults(resultData);
        } catch (error) {
            setError(error.message);
        }
    };

    // Conditional Rendering for Loading, Error, or Assessment Result
    if (loading) {
        console.log('AssessemntResults showing loader');
        return <Loader />;
    }

    if (error) {
        return <p>Error: {error}</p>;
    }

    return (
        <div>
            <Header
                firstName={userData?.firstName || currentUser?.email || 'User'}
                onLogout={async () => {
                    try {
                        setLogoutError(null);
                        await logout();
                        navigate('/login');
                    } catch (error) {
                        console.error('Failed to log out', error);
                        setLogoutError('Failed to log out. Please try again.');
                    }
                }}
                isAssessmentPage={true}
            />

            <div className="assessment-results-container">
                <div className="results-content">
                    <main style={{ textAlign: 'center', padding: '2rem' }}>
                        <h2 className="custom-heading">Your MCA Report</h2>
                        <div className="dark-results-block">
                            <p className="slab-light">Welcome to your Marriage Connection Assessment results! We're excited to share with you the insights gathered from your responses, covering 36 aspects of married life organized into seven major areas. These areas are:</p>
                            <div className="areas-list slab-heavy">
                                <p>1. Trust</p>
                                <p>2. Communication & Conflict Resolution</p>
                                <p>3. Forgiveness</p>
                                <p>4. Emotional Connection</p>
                                <p>5. Sex</p>
                                <p>6. Teamwork</p>
                                <p>7. Stress Management</p>
                            </div>
                            <p className="slab-light">This comprehensive overview is designed to help you and your partner understand your relationship's strengths and identify areas for growth.<br></br><br></br>
                                By exploring these results together, you'll gain valuable insights that can help you build a lasting and fulfilling relationship.<br></br><br></br>
                                Remember, marriage is a journey, and growth takes time. These results are not a final judgment but a snapshot of where you are now and a guide for where you might want to focus your efforts moving forward.<br></br><br></br>
                                We hope this information serves as a helpful tool in your ongoing journey together. Every couple faces challenges, and seeking to understand and improve your relationship is a significant step towards long-term happiness.<br></br><br></br>
                                As you review your results, we encourage you to approach them with an open mind and a spirit of collaboration. Celebrate your strengths and view your growth areas as opportunities to deepen your connection.<br></br><br></br>
                                We wish you all the best as you continue to nurture and strengthen your marriage. Here's to your shared journey of love, growth, and mutual understanding!
                            </p>
                        </div>
                    </main>
                </div>
            </div>

            {!hasPartnerResult && (
                <div className="no-spouse-block">
                    <div className="no-spouse-assessment-container">
                        <p className="no-spouse-note-title">NOTE:</p>
                        <p className="no-spouse-note-text">We haven't received your spouse's assessment yet. When they finishes the assessment, return here to view your shared results.</p>
                    </div>
                </div>
            )}

            {results && results.processedAssessments && results.processedAssessments.map((assessment, index) => (
                <div className="assessment-results-container">
                    <div className="results-content">
                        <main style={{ textAlign: 'center', padding: '2rem' }}>

                            <AssessmentBlock
                                key={`assessment-${index}`}
                                userAssessment={assessment}
                                spouseAssessment={spouseResults?.processedAssessments[index]}
                                userData={userData}
                                spouseName={spouseName}
                            />

                        </main>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default AssessmentResults;