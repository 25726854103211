import { collection, doc, getDocs, setDoc } from 'firebase/firestore';
import { db } from '../auth/firebase';
import { getAuth } from 'firebase/auth';
import { cleanData } from '../utils/cleanData';

class AssessmentProcessor {
  constructor(manifestTitle) {
    this.manifestTitle = manifestTitle;  // Manifest title passed in
    this.userResponses = [];  // To store all user responses
    this.processedResult = {
      assessmentCollection: "marriage-connection-assessment",
      assessmentCollectionTitle: "Marriage Connection Assessment",
      timestamp: new Date(),
      assessmentScore: 0,  // Updated later
      maxScore: 0, //Updated later
      assessmentScoreDescription: "pending",  // Updated later
      processedAssessments: []  // Array to hold processed assessments
    };
  }


  //----------------------------------
  //
  // Step 1: 
  // Fetch user responses from Firestore
  //
  //----------------------------------

  async fetchUserResponses() {
    const auth = getAuth();
    const currentUser = auth.currentUser;

    if (!currentUser) {
      throw new Error('No user is currently authenticated.');
    }
    const userId = currentUser.uid;
    const userResponsesRef = collection(db, 'users', userId, 'userResponses');
    const snapshot = await getDocs(userResponsesRef);
    this.userResponses = snapshot.docs.map(doc => doc.data());
    console.log('==========================');
    console.log('STEP ONE: USER RESPONSES');
    console.log(this.userResponses.length);
    console.log('==========================');
  }


  //----------------------------------
  //
  // Step 2: 
  // Load Assessment Manifest
  //
  //----------------------------------


  async loadManifest() {
    try {
      const response = await fetch('/data/assessmentManifest-v2.json');
      if (!response.ok) {
        throw new Error(`Unable to load assessment manifest.`);
      }
      console.log('==========================');
      console.log('STEP TWO: PARSED MANIFEST');
      console.log('==========================');

      return await response.json();
    } catch (error) {
      console.error('Error loading assessment manifest:', error);
      throw new Error('Unable to load assessment manifest.');
    }
  }


  //----------------------------------
  //
  // Step 3: 
  // Process and Push 
  //
  //----------------------------------


  async processAssessments() {

    console.log('AP ----> In process assessments method');
    await this.fetchUserResponses();  // Fetch the user responses first
    const manifest = await this.loadManifest();  // Get the manifest JSON
    const assessments = manifest.assessments;  // Get the assessments array

    console.log('AP ----> Have manifests');

    console.log('==========================');
    console.log('STEP THREE: MANIFESTS')
    console.log(assessments)
    console.log('==========================');

    for (const assessment of assessments) {

      console.log('AP ----> loading manifest: ', assessment.title);

      const id = assessment.id;
      const title = assessment.title;
      const manifestDescription = assessment.description;
      const jsonFile = assessment.jsonFile;

      console.log('AP ----> LOADED');
      console.log('AP ---->    title: ', title);
      console.log('AP ---->    description: ', manifestDescription);

      // Step 4: Create a processed assessment object
      let processedAssessment = {
        id,
        title,
        description: manifestDescription,
        maxScore: 0, // To be updated
        assessmentScore: 0,  // To be updated
        assessmentScoreDescription: "",  // To be updated
        processedAspects: []  // Store processed aspects
      };

      console.log('AP ----> loading associated userResponses.');

      // Step 5: Match user responses to the current assessment
      let assessmentResponses = this.userResponses.filter(response => {
        const assessmentRef = response.assessment;  // Firestore DocumentReference
        return assessmentRef && assessmentRef.id === id;  // Compare DocumentReference ID to string ID
      });

      console.log('AP ----> got responses: ', assessmentResponses.length);

      // Load the JSON file dynamically for the assessment aspects
      const assessmentJson = await this.loadAssessmentJson(jsonFile);
      processedAssessment.maxScore = assessmentJson.maxScore || 0;

      console.log('AP ----> JSON File Loaded: ', assessmentJson);

      console.log('AP ----> JSON File Aspects: ', assessmentJson.aspects);

      

      // Step 6: Process aspects of the current assessment
      await this.processAspects(assessmentJson.aspects, assessmentResponses, processedAssessment);

      console.log('AP ----> PROCESSED ASSESSMENT: ', processedAssessment);

      // Step 7: Calculate total score for the assessment
      this.calculateAssessmentScore(processedAssessment, assessmentJson.scoreDescriptions);

      // Add the processed assessment to the result
      this.processedResult.processedAssessments.push(processedAssessment);

      console.log('AP ----> Added assessment to processed result: ', this.processedResult);

    }

    console.log('AP ----> Saving results to FireStore');

    // Step 8: Save the result to Firestore and add the reference to the user's document
    await this.saveResultToFirestore();

    console.log('AP ----> Saved!');

    return "success";  // Return success to the calling function
  }


  //----------------------------------
  //
  // Step 4: 
  // Load Assessment JSON
  //
  //----------------------------------


  async loadAssessmentJson(jsonFile) {
    try {
      // Fetch the JSON file directly using the filename in the jsonFile field
      const response = await fetch(`/data/${jsonFile}`);
      if (!response.ok) {
        throw new Error(`Unable to load assessment data from ${jsonFile}`);
      }
      const jsonData = await response.json();
      return jsonData;
    } catch (error) {
      console.error(`Error loading the JSON file: ${jsonFile}`, error);
      throw new Error(`Unable to load assessment data from ${jsonFile}`);
    }
  }



  //----------------------------------
  //
  // Step 5: 
  // Process Aspects
  //
  //----------------------------------


  async processAspects(aspects, assessmentResponses, processedAssessment) {
    for (const aspect of aspects) {
      const calcIds = aspect.calcIds;
      const title = aspect.title;
      const description = aspect.description;
      const scoreDescriptions = aspect.scoreDescriptions;
      const aspectMaxScore = aspect.maxScore;

      let matchedResponses = assessmentResponses.filter(response =>
        calcIds.includes(response.calcId)  // Match calcId
      );

      // Step 7: Calculate total score and confidence
      let totalScore = this.calculateTotalScore(matchedResponses);
      let confidenceValue = this.calculateConfidence(matchedResponses);

      // Get the score description based on the score
      let scoreDescription = this.getScoreDescription(totalScore, scoreDescriptions);

      // Add the processed aspect to the processedAssessment object
      processedAssessment.processedAspects.push({
        title,
        description,
        totalScore,
        aspectMaxScore,
        scoreDescription,
        confidenceValue
      });
    }
  }

  // Calculate total score from matched responses
  calculateTotalScore(responses) {
    return responses.reduce((acc, response) => acc + response.answerIndex, 0);
  }

  // Calculate confidence based on answer similarity
  calculateConfidence(responses) {
    if (responses.length < 2) return 1.0;
    let avg = this.calculateAverage(responses.map(res => res.answerIndex));
    let variance = responses.reduce((sum, res) => sum + Math.pow(res.answerIndex - avg, 2), 0) / responses.length;
    return Math.exp(-variance);
  }

  // Get the score description based on total score
  getScoreDescription(totalScore, scoreDescriptions) {
    let scoreDescription = "";
    const sortedEntries = Object.entries(scoreDescriptions).sort((a, b) => parseInt(b[0]) - parseInt(a[0]));

    for (let [key, description] of sortedEntries) {
      if (totalScore >= parseInt(key)) {
        scoreDescription = description;
        break;
      }
    }
    return scoreDescription;
  }

  // Calculate the total score and update the processed assessment
  calculateAssessmentScore(processedAssessment, scoreDescriptions) {
    let totalAssessmentScore = processedAssessment.processedAspects.reduce((acc, aspect) => acc + aspect.totalScore, 0);
    processedAssessment.assessmentScore = totalAssessmentScore;

    // Get the score description for the total score
    let assessmentScoreDescription = this.getScoreDescription(totalAssessmentScore, scoreDescriptions);
    processedAssessment.assessmentScoreDescription = assessmentScoreDescription;
  }

  async saveResultToFirestore() {
    // 1. Get the current authenticated user
    console.log('AP ----> In Saving Results function');

    const auth = getAuth();
    const currentUser = auth.currentUser;

    if (!currentUser) {
      throw new Error('No user is currently authenticated.');
    }

    console.log('AP ----> Got Current User');

    // Get the userId from the authenticated user
    const userId = currentUser.uid;

    console.log('AP ----> UserId: ', userId);

    // 2. Create a new result document in the user's "results" subcollection
    const resultRef = doc(collection(db, 'users', userId, 'results'));

    console.log('AP ----> Processed Result: ', this.processedResult);

    // 3. Save the processed result into the result document
    await setDoc(resultRef, this.processedResult);

    console.log('AP ----> Results saved to user.');

    // 4. Update the user's document to include the reference to the current result
    const userRef = doc(db, 'users', userId);

    // Use setDoc with merge to ensure the currentResult field is added or updated
    // TODO -- WHAT IS THIS FOR??
    await setDoc(userRef, {
      currentResult: resultRef // Store the reference to the result document
    }, { merge: true });

    return resultRef; // Return the reference to the newly saved result document
  }

  // Utility: Calculate average value
  calculateAverage(values) {
    return values.reduce((a, b) => a + b, 0) / values.length;
  }
}

export default AssessmentProcessor;