import { collection, getDocs, query, where, doc, updateDoc, getDoc } from 'firebase/firestore';
import { db } from '../auth/firebase';

export const linkSpouses = async (currentUser) => {
  try {
    console.log('LinkSpouses: -- Looking for spouse ref in current user.');

    const { email, uid, coupleCode } = currentUser;

    // Step 1: Check if the current user already has a spouse reference
    const userRef = doc(db, 'users', uid);
    const userSnapshot = await getDoc(userRef);

    if (userSnapshot.exists()) {
      const userData = userSnapshot.data();
      console.log('Current user data:', userData);
      if (userData.spouse) {
        console.log('LinkSpouses: -- Spouse reference already exists:', userData.spouse);
        return; // Spouse already linked, nothing to do
      }
    }

    // Step 2: Query preUsers collection to find the spouse (preUsers with the same coupleCode but different email)
    const preUserQuery = query(
      collection(db, 'preUsers'),
      where('coupleCode', '==', coupleCode),
      where('email', '!=', email)
    );
    const preUserSnapshot = await getDocs(preUserQuery);

    if (preUserSnapshot.empty) {
      console.log('LinkSpouses: -- No linked spouses found in PreUsers.');
      return;
    }

    // Step 3: Get the spouse's email
    const linkedPreUser = preUserSnapshot.docs[0].data();
    const linkedEmail = linkedPreUser.email;
    console.log('LinkSpouses: -- Found preUser for spouse, email:', linkedEmail);

    // Step 4: Query the 'users' collection to find the user with the spouse's email
    const userQuery = query(collection(db, 'users'), where('email', '==', linkedEmail));
    const linkedUserSnapshot = await getDocs(userQuery);

    if (linkedUserSnapshot.empty) {
      console.log('LinkSpouses: -- Linked preUser has no user account.');
      return;
    }

    const linkedSpouse = linkedUserSnapshot.docs[0];
    const linkedSpouseRef = doc(db, 'users', linkedSpouse.id);

    // Step 5: Update both users' documents with a reference to each other
    console.log('LinkSpouses: -- Linking current user and spouse in Firestore.');
    await updateDoc(userRef, {
      spouse: linkedSpouseRef
    });
    await updateDoc(linkedSpouseRef, {
      spouse: userRef
    });

    console.log('LinkSpouses: -- Spouse linking complete for both users.');

  } catch (error) {
    console.error('LinkSpouses: xxxxxxx Error linking spouses:', error);
  }
};