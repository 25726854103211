import React from 'react';
import Lottie from 'react-lottie-player'; 
import lock from './lock.json';

const LockLottie = () => {
  return (
    <div className="email-lottie-container">
      <Lottie
        loop
        animationData={lock}
        play
        style={{ width: 200, height: 200 }}  // Customize the loader size
      />
    </div>
  );
};

export default LockLottie;